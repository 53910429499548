import { Label, RangeSlider } from '@loveholidays/design-system';
import { useTranslation } from '@loveholidays/phrasebook';
import React, { useCallback, useMemo } from 'react';

import { ClassNameProps } from '@ComponentProps';
import { StaticPrice } from '@Components/Price/StaticPrice';
import { calculateAverageStep } from '@Core/helpers/array';
import { FilterItemModel } from '@UX/components/Filter/FilterItemModel';

export type FilterRangeProps = ClassNameProps & {
  items: FilterItemModel[];
  selected: FilterItemModel['id'];
  onChange: (selected?: FilterItemModel['id']) => void;
};

type RangeFilterItemModel = Omit<FilterItemModel, 'id'> & { id: number };

type UseFilterRangeValues = {
  isValid: boolean;
  range: RangeFilterItemModel[];
  bounds: {
    min: RangeFilterItemModel;
    max: RangeFilterItemModel;
  };
  step: number;
  hasAny: boolean;
};

export const useFilterRangeValues = (items: FilterItemModel[]): UseFilterRangeValues => {
  return useMemo(() => {
    // hold onto 'any' item
    const anyLabel = items.find(({ id }) => id === 'any');

    // Filter, map and sort values for range
    const rangeValues = items
      .reduce<RangeFilterItemModel[]>((acc, item) => {
        if (!isNaN(Number(item.id))) {
          acc.push({
            ...item,
            id: Number(item.id),
          });
        }

        return acc;
      }, [])
      .sort((a, b) => a.id - b.id);

    // Calculate step between values
    const step = calculateAverageStep(rangeValues.map(({ id }) => id));

    let range = rangeValues;
    if (rangeValues.length && anyLabel) {
      range.push({
        ...anyLabel,
        id: rangeValues[rangeValues.length - 1].id + step,
      });
    }

    // Set bounds
    const min = range[0];
    const max = range[range.length - 1];

    return {
      isValid: !!rangeValues.length && min.id !== max.id,
      range,
      bounds: { min, max },
      step,
      hasAny: !!anyLabel,
    };
  }, [items]);
};

export const FilterRange: React.FC<FilterRangeProps> = ({
  className,
  'data-id': dataId,
  items,
  selected,
  onChange,
}) => {
  const { t } = useTranslation();

  const { isValid, range, bounds, step, hasAny } = useFilterRangeValues(items);

  const formatLabel = useCallback(
    (value: number): string => {
      const selectedItem = range.find(({ id }) => id === value);
      if (selectedItem?.label && typeof selectedItem.label === 'string') {
        return selectedItem.label;
      }

      return '';
    },
    [range],
  );

  if (!isValid) {
    return null;
  }

  const selectedId = selected && !isNaN(Number(selected)) ? Number(selected) : bounds?.max.id;

  return (
    <div
      className={className}
      data-id={`filter-range-${dataId}`}
      sx={{
        marginY: 'xs',
        paddingX: 's',
      }}
    >
      <RangeSlider
        ariaLabel={t('journeyDurationSlider')}
        min={bounds.min.id}
        max={bounds.max.id}
        step={step}
        value={selectedId}
        formatValue={formatLabel}
        showProgressLabel
        formatProgressLabel={formatLabel}
        onChange={(value) => {
          onChange(hasAny && value === bounds.max.id ? undefined : value);
        }}
      />

      <div
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          color: 'textDimmedmedium',
        }}
      >
        <Label variant="extrasmall">
          {/*
          Hack to get the test to work for prices - design might want to iterate this part anyway
          so a necessary evil for now
           */}
          <StaticPrice value={bounds.min.id} />
        </Label>
        <Label variant="extrasmall">{bounds.max.label}</Label>
      </div>
    </div>
  );
};
